import jsPDF from "jspdf";
import "jspdf-autotable";
import JsBarcode from "jsbarcode";
import {datefr} from "@/utils/functions";

 export function ticket(item) {
  const doc = new jsPDF("p", "mm", [58, 60]); // Orientation portrait, dimensions 58mm x 45mm

  // Détails du ticket
  const productCode = item.ref;
  const description = item.label;
  const date = item.created_at;
  const qte_brut = item.qte;
  const qte_net = item.qte_net;
  const client_name = item.client_name;

  // Générer le Code-Barres
  const barcodeCanvas = document.createElement("canvas");
  JsBarcode(barcodeCanvas, productCode, {
    format: "CODE128",
    width: 2,
    height: 15,
    displayValue: false, // Pas de texte sous le code-barres
  });
  const barcodeDataURL = barcodeCanvas.toDataURL("image/png");

  // Positionner les éléments
  let y = 15;
  doc.addImage(barcodeDataURL, "PNG", 5, y, 50, 15); // Ajustement du positionnement du Code-Barres
  y += 14;

  doc.setFont("helvetica", "normal");
  doc.setFontSize(8);
  doc.text(productCode, 17, y);
  y += 4;
  doc.setFont("helvetica", "normal");
  doc.text(datefr(date, true,'numeric'), 5, y);
  y += 5;

  // Gestion du poids
  if (item.unit_category == 2) {
    doc.setFont("helvetica", "bold");
    doc.text(`Brut: ${qte_brut.toFixed(2)} kg`, 5, y);
    doc.text(`Net: ${qte_net.toFixed(2)} kg`, 30, y);
  } else {
    doc.text(
      `${this.numberWithSpace(qte_brut.toFixed(2))} ${item.unit || ""}`,
      5,
      y
    );
  }
  y += 5;

  // Gestion de la description avec retour automatique à la ligne
  doc.setFont("helvetica", "bold");
  doc.setFontSize(8);
  doc.text("Client : ", 5, y);
  doc.setFont("helvetica", "normal");
  const client_nameLines = doc.splitTextToSize(client_name, 54);
  doc.text(client_nameLines, 15, y);
  y += 5;
  const descriptionLines = doc.splitTextToSize(description, 54); // Ajuste la largeur max
  doc.text(descriptionLines, 5, y);

  // Forcer l'impression
  // doc.autoPrint();
  // window.open(doc.output("bloburl"));

  // Sauvegarder le fichier PDF dans un Blob
  return doc.output("blob");

  
}