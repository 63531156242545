<template>
  <v-container fluid>
    <v-tabs v-model="tabs" class="my-tabs" @change="get_data">
      <v-tab> {{ prd_type == "p" ? "Par Palettes " : "Actuel" }} </v-tab>
      <v-tab> {{ prd_type == "p" ? "Par Produits" : "Consommé" }} </v-tab>
    </v-tabs>
    <v-row>
      <v-col
        cols="12"
        sm="12"
        md="12"
        v-if="(prd_type == 'p' && tabs == '1') || prd_type == 'i'"
      >
        <listitems
          :list="encours_list"
          :title="prd_type == 'p' ? 'Produits ' : 'Intrants'"
          :headers="headers"
          :filename="prd_type == 'p' ? 'Produits ' : 'Intrants'"
          :sheetname="prd_type == 'p' ? 'Produits ' : 'Intrants'"
          :showedit="false"
          :key="klist"
          @open="ProductOpen"
          @rowselect="ProductSelect"
          :add="false"
          :del="false"
          :Total="true"
          :laoding="loading"
          :search_elm="filtre"
        >
        </listitems>
      </v-col>
      <v-col cols="12" sm="6" md="6" v-if="prd_type == 'p' && tabs == '0'">
        <listitemspage
          :selitem="selitem"
          :qSelect="Qselect"
          :qSelectall="Qselectall"
          :PageResponse="'palettes'"
          :AllResponse="'allpalettes'"
          :title="'Palettes'"
          :headers="palheaders"
          :field="'CREATED_AT'"
          :filename="'Palettes'"
          :sheetname="'Palettes'"
          @rowselect="PaletteChange"
          :showedit="false"
          :Add="false"
          :del="false"
          :list_options="false"
          :key="klist2"
          @col_btn2_click="palette_close"
          @col_btn1_click="print_palette_ticket"
        >
        </listitemspage>
      </v-col>
      <v-col cols="12" sm="6" md="6" v-if="prd_type == 'p' && tabs == '0'">
        <listitems
          :list="palette_items"
          :headers="outputheaders"
          :title="palette.ref ? 'Palette : ' + palette.ref : ''"
          :master="true"
          :add="false"
          :del="false"
          :Update="false"
          :showedit="false"
          :showstd="false"
          :Total="true"
          :print="false"
          :exp_excel="false"
          :key="kpal"
          :ipg="15"
          @col_btn1_click="print_ticket"
          @col_btn2_click="delete_item"
          @col_btn4_click="declasse_item"
        >
        </listitems>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
    <confirmdialog ref="confirm" :is_motif="is_motif" @motif="get_motif" />
    <formlist
      :title="title"
      :list="form_list"
      :showform="!isListClose"
      :listheaders="headers_detail"
      :key="frml"
      :width="width"
      @close="isListClose = true"
      :Total="true"
      :master="true"
      :laoding="loading"
      @btn_click="consomed"
      @col_btn1="print_palette_ticket"
    >
    </formlist>
  </v-container>
</template>

<script>
import DELETE_FICHE_DETAIL from "../graphql/Production/DELETE_FICHE_DETAIL.gql";
import PRODUCTION_LIST_CUMULE from "../graphql/Production/PRODUCTION_LIST_CUMULE.gql";
import PRODUCT_LIST_CUMULE from "../graphql/Production/PRODUCT_LIST_CUMULE.gql";
import PRODUCT_LIST_INPUT from "../graphql/Product/PRODUCT_LIST_INPUT.gql";
import UPDATE_PRODUCT_LIST from "../graphql/Product/UPDATE_PRODUCT_LIST.gql";
import PRODUCTION_LIST from "../graphql/Production/PRODUCTION_LIST.gql";
import UPDATE_FICHE_DETAIL from "../graphql/Production/UPDATE_FICHE_DETAIL.gql";
import PALETTES from "../graphql/Production/PALETTES.gql";
import ALLPALETTES from "../graphql/Production/ALLPALETTES.gql";
import ALLATTRIBUTS from "../graphql/Template/ALLATTRIBUTS.gql";
import UPDATE_PALETTE from "../graphql/Production/UPDATE_PALETTE.gql";
import { use_balance, myName } from "print/data.js";
import QRCode from "qrcode";
import jsPDF from "jspdf";
import axios from "axios";
import { ticket } from "print/ticket.js";
export default {
  components: {
    listitemspage: () => import("../components/ListItemsPages.vue"),
    listitems: () => import("../components/ListItems.vue"),
    formlist: () => import("../components/FormList.vue"),
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
  },
  props: {
    prd_type: String,
  },
  data: () => ({
    filtre: "",
    tabs: "0",
    palette_items: [],
    is_motif: false,
    selitem: {},
    title: "",
    loading: false,
    encours_list: [],
    form_list: [],
    colisheaders: [],
    attributes: [],
    width: "1200px",
    palheaders: [
      {
        text: "Code",
        value: "ref",
        enum: "REF",
        selected: true,
      },
      {
        text: "Date",
        value: "created_at",
        enum: "CREATED_AT",
        slot: "date",
        selected: false,
      },
      {
        text: "Quantité",
        value: "qte_brut",
        total: "qte_brut",
        slot: "cur",
        align: "end",
        enum: "QTE_BRUT",
        selected: true,
        dec: 2,
      },
      {
        text: "Poids Net (Kg)",
        value: "qte_net",
        total: "qte_net",
        slot: "cur",
        align: "end",
        hiden: true,
        enum: "QTE_NET",
        selected: true,
        dec: 2,
      },
      {
        text: "Poids Palette (Kg)",
        value: "poids",
        total: "poids",
        slot: "cur",
        align: "end",
        enum: "POIDS",
        selected: false,
        dec: 2,
      },
      {
        text: "Nb. Items",
        value: "nb_items",
        total: "nb_items",
        align: "end",
        slot: "cur",
        dec: 0,
        enum: "NB_ITEMS",
        selected: true,
      },
      {
        text: "N° Fiche",
        value: "nofiche",
        enum: "NOFICHE",
        selected: false,
      },
      {
        text: "Client",
        value: "client_name",
        enum: "CLIENT_NAME",
        selected: true,
      },
      {
        text: "Machine",
        value: "machine_name",
        enum: "MACHINE_NAME",
        selected: true,
      },
      {
        text: "Rcpt.",
        value: "qte_cons",
        total: "qte_cons",
        slot: "cur",
        align: "end",
        enum: "QTE_CONS",
        selected: false,
        dec: 2,
      },
      {
        text: "%",
        value: "prc_rest",
        slot: "progress",
        align: "end",
        enum: "PRC_REST",
        selected: false,
        width: "100",
      },
      {
        text: "",
        slot: "col_btn1",
        icon: "mdi-printer",
        column: "closed",
        valeur: 1,
        selected: true,
      },
      {
        text: "",
        slot: "col_btn2",
        selected: true,
        icon: "mdi-close-circle-multiple-outline",
        tooltip: "Cloturer la palette",
        column1: "closed",
        valeur1: 0,
        column2: "nb_items",
        operator2: "<>",
        valeur2: 0,
        sortable: false,
        width: "1%",
        index: 26,
      },
      {
        text: "",
        slot: "col_btn2",
        selected: true,
        icon: "mdi-restore",
        tooltip: "Restorer la palette",
        column1: "closed",
        valeur1: 1,
        sortable: false,
        width: "1%",
        index: 27,
      },
    ],
    outputheaders: [
      {
        text: "No",
        value: "no",
        selected: true,
        totlib: true,
      },
      {
        text: "Code",
        value: "ref",
        selected: true,
        expire: true,
      },
      {
        text: "Produit",
        value: "label",
        selected: true,
      },
      {
        text: "Quantité",
        value: "qte",
        total: "qte",
        slot: "cur",
        align: "end",
        selected: true,
        width: "100",
        dec: 2,
      },
      {
        text: "Poids Net (Kg)",
        value: "qte_net",
        total: "qte_net",
        slot: "cur",
        align: "end",
        hiden: true,
        selected: true,
        width: "100",
        dec: 2,
      },
      {
        text: "Unité",
        value: "unit",
        selected: false,
      },
      {
        text: "Date",
        value: "created_at",
        slot: "date",
        selected: true,
      },
      {
        text: "Palette",
        value: "palette",
        selected: true,
      },
      {
        text: "N° Cmd",
        value: "nocmd",
        selected: false,
      },
      {
        text: "Client",
        value: "client_name",
        selected: false,
      },
      {
        text: "Comment",
        value: "comment",
        selected: false,
      },
      {
        text: "",
        slot: "col_btn1",
        icon: "mdi-printer",
        selected: true,
      },
      {
        text: "",
        slot: "col_btn2",
        icon: "mdi-delete",
        column: "qte_cons",
        valeur: 0,
        selected: true,
      },
      {
        text: "",
        slot: "col_btn4",
        icon: "mdi-alert-plus",
        column1: "conforme",
        valeur1: "1",
        column2: "qte_cons",
        valeur2: 0,
        selected: true,
      },
      {
        text: "",
        slot: "col_btn4",
        icon: "mdi-check",
        column1: "conforme",
        valeur1: "0",
        column2: "qte_cons",
        valeur2: 0,
        selected: true,
      },
    ],
    colisheaders2: [
      {
        text: "No",
        value: "no",
        selected: true,
        totlib: true,
      },
      {
        text: "Code",
        value: "ref",
        selected: true,
      },
      {
        text: "Livrée",
        value: "qte_sortie",
        slot: "cur",
        total: "qte_sortie",
        align: "end",
        selected: true,
      },
      {
        text: "Reste",
        value: "qte_rest",
        slot: "cur",
        total: "qte_rest",
        align: "end",
        selected: true,
      },
      {
        text: "Reste (%)",
        value: "prc_rest",
        slot: "progress",
        align: "end",
        selected: true,
      },

      {
        text: "Comment",
        value: "comment",
        selected: true,
      },
      {
        text: "Motif",
        value: "motif",
        hiden: true,
        selected: true,
      },
      {
        text: "",
        slot: "actions",
        selected: true,
        hiden: true,
        icon: "mdi-delete",
        tooltip: "Supprimer (consommé)",
      },
    ],
    headers_detail: [],
    isListClose: true,
    motif: "",
    headers: [
      {
        text: "No",
        value: "no",
        selected: true,
        hiden: true,
      },
      {
        text: "Ref",
        value: "article_code",
        selected: true,
        totlib: true,
      },
      {
        text: "Libelle",
        align: "start",
        value: "product_label",
        selected: true,
      },
      {
        text: "Fournisseur",
        value: "fournis_name",
        selected: true,
        hiden: true,
      },
      {
        text: "Qte",
        value: "qte_rest",
        selected: true,
        slot: "cur",
        width: "150",
        align: "end",
      },
      {
        text: "U.M.",
        value: "unit",
        selected: true,
      },
      {
        text: "Nbr. Item",
        value: "nbpfd",
        slot: "href22",
        total: "nbpfd",
        selected: true,
        align: "end",
      },
      {
        text: "Nbr. Pal.",
        value: "nbpal",
        slot: "href3",
        total: "nbpal",
        selected: true,
        align: "end",
      },

      {
        text: "N° OF",
        value: "no_order",
        selected: true,
      },
      {
        text: "Client",
        value: "client_name",
        hiden: true,
        selected: true,
      },
      {
        text: "N° Cmd",
        value: "nocmd",
        hiden: true,
        selected: true,
      },
      {
        text: "Date Cmd",
        value: "date_cmd",
        hiden: true,
        slot: "date",
        selected: true,
      },
      {
        text: "Machine",
        value: "machine_name",
        hiden: true,
        selected: true,
      },
      {
        text: "Etape",
        value: "gamme_name",
        selected: true,
      },
      {
        text: "Statut",
        value: "statut_name",
        selected: true,
        expire: true,
        cloture: true,
      },
    ],
    headers_production: [
      {
        text: "No",
        value: "no",
        selected: true,
        hiden: true,
        ind: 0,
      },
      {
        text: "Code",
        value: "code",
        selected: true,
        totlib: true,
        ind: 1,
      },
      {
        text: "Qte",
        value: "qte",
        selected: true,
        total: "qte",
        slot: "cur",
        align: "end",
        ind: 2,
      },
      {
        text: "Reste",
        value: "qte_rest",
        selected: true,
        total: "qte_rest",
        slot: "cur",
        align: "end",
        hiden: true,
        ind: 3,
      },
      {
        text: "Reste (%)",
        value: "prc_rest",
        selected: true,
        slot: "progress",
        hiden: true,
        ind: 4,
      },
      {
        text: "N° Fiche",
        value: "nofiche",
        selected: true,
        ind: 5,
      },
      {
        text: "N° BT",
        value: "no_bt",
        selected: true,
        ind: 6,
      },
      {
        text: "Machine",
        value: "machine_name",
        selected: true,
        ind: 7,
      },
      {
        text: "Origine",
        value: "origin",
        selected: true,
        ind: 8,
      },
      {
        text: "Motif",
        value: "motif",
        hiden: true,
        selected: true,
        ind: 9,
      },
      {
        text: "Palette",
        value: "palette",
        selected: true,
        ind: 10,
      },

      {
        text: "",
        slot: "actions",
        selected: true,
        hiden: true,
        icon: "mdi-delete",
        tooltip: "Supprimer (Etant consommé)",
        ind: 11,
      },
    ],
    product: {},
    palette: {},
    klist: 0,
    klist2: 145,
    frml: 100,
    kpal: 250,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
  }),

  computed: {
    Qselect() {
      return PALETTES;
    },
    Qselectall() {
      return ALLPALETTES;
    },
    attributs_list() {
      return this.attributes.filter((elm) => elm.type_attr == "colis");
    },
  },
  watch: {
    prd_type: {
      handler() {
        this.filtre = "";
        this.get_data();
      },
      deep: true,
    },
    "$store.state.filtre": {
      handler() {
        if (this.$store.state.filtre) {
          this.filtre = this.$store.state.filtre.filter_by;
          this.selitem = this.$store.state.filtre.obj;
          this.klist++;
        }
      },
    },
  },
  created() {
    if (this.$route.params.filtre) {
      this.filtre = this.$route.params.filtre;
      this.selitem = this.$route.params.obj;
      this.klist++;
    }
    this.headers[3].hiden = this.prd_type == "p";
    this.headers[9].hiden = this.prd_type == "i";
    this.headers[10].hiden = this.prd_type == "i";
    this.headers[11].hiden = this.prd_type == "i";
    this.headers[12].hiden = this.prd_type == "p";
    this.headers[13].hiden = this.prd_type == "i";
    this.headers[14].hiden = this.prd_type == "i";
  },

  async mounted() {
    this.palheaders[2].text = "Quantité";
    this.palheaders[3].hiden = true;
    this.outputheaders[3].text = "Quantité";
    this.outputheaders[4].hiden = true;

    if (use_balance) {
      this.palheaders[2].text = "Poids Brut (Kg)";
      this.palheaders[3].hiden = false;
      this.outputheaders[3].text = "Poids Brut (Kg)";
      this.outputheaders[4].hiden = false;
    }
  },

  methods: {
    async palette_close(item) {
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Etês-vous sûr de vouloir " +
            (item.closed == 0 ? "clôturer" : "restorer") +
            " cette palette?"
        )
      ) {
        let r = await this.maj(UPDATE_PALETTE, {
          palette: { id: item.id, closed: item.closed == 0 ? 1 : 0 },
        });
        if (r) {
          item.closed = item.closed == 0 ? 1 : 0;
          this.klist2++;
        }
      }
    },
    async delete_item(item) {
      let ok = true;
      this.alert = false;
      let v = {
        pfd_id: item.id,
      };
      let r = await this.requette(PRODUCTION_LIST, v);
      if (r) {
        let i = r.production_list.findIndex(
          (elm) => elm.qte_cons > 0 || elm.consomed == "1"
        );
        if (i >= 0) {
          this.message = "Production consommée--Suppression impossible";
          this.type_alert = "error";
          this.alert = true;
          ok = false;
        }
      }
      if (ok)
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Etês-vous sûr de vouloir supprimer cet element?"
          )
        ) {
          v = {
            id: item.id,
          };
          r = await this.maj(DELETE_FICHE_DETAIL, v);
          if (r) {
            this.$store.dispatch("Changed", true);
          } else {
            this.message = "Erreur de suppression";
            this.type_alert = "error";
            this.alert = true;
          }
        }
    },

    async declasse_item(item) {
      if (item.conforme == 0 && !this.last_palette) {
        this.snackbar_text = "Aucune Palette pour restaurer cet element!";
        this.snackbar_color = "error";
        this.snackbar = true;
        return;
      }

      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Etês-vous sûr de vouloir " +
            (item.conforme == 1 ? "declasser" : "restorer ") +
            " cet element" +
            (item.conforme == 0
              ? " dans la palette " + this.last_palette.ref
              : "") +
            " ?"
        )
      ) {
        let v = {
          fichedetail: {
            id: item.id,
            conforme: item.conforme == 1 ? 0 : 1,
            pal_id: item.conforme == 1 ? null : this.last_palette.id,
          },
        };
        let r = await this.maj(UPDATE_FICHE_DETAIL, v);
        if (r) {
          item.pal_id = item.conforme == "1" ? null : this.last_palette.id;
          item.conforme = item.conforme == "1" ? "0" : "1";
          if (item.conforme == "0") {
            this.palette_items.splice(this.palette_items.indexOf(item), 1);
            let i = this.palettes_list.findIndex(
              (elm) => elm.id == this.palette.id
            );
            if (i >= 0) {
              this.palettes_list[i].nb_items =
                this.palettes_list[i].nb_items - 1;
            }
            i = this.palettes_list.findIndex((elm) => elm.id == -1);
            if (i >= 0) {
              this.palettes_list[i].items.unshift(item);
              this.palettes_list[i].nb_items =
                this.palettes_list[i].nb_items + 1;
            }
          }
          if (item.conforme == "1") {
            this.palette_items.splice(this.palette_items.indexOf(item), 1);
            let i = this.palettes_list.findIndex((elm) => elm.id == -1);
            if (i >= 0) {
              this.palettes_list[i].nb_items =
                this.palettes_list[i].nb_items - 1;
            }

            i = this.palettes_list.findIndex(
              (elm) => elm.id == this.last_palette.id
            );
            if (i >= 0) {
              this.palettes_list[i].items.unshift(item);
              this.palettes_list[i].nb_items =
                this.palettes_list[i].nb_items + 1;
            }
          }

          this.frml++;
          this.snackbar_text = "Mise a jour effectuée";
          this.snackbar_color = "success";
          this.snackbar = true;
        } else {
          this.snackbar_text = "Erreur de mis a jour";
          this.snackbar_color = "error";
          this.snackbar = true;
        }
      }
    },
    async print_ticket(item) {
      const qrCodeDataURL = await QRCode.toDataURL(item.ref);
      // Sauvegarder le fichier PDF dans un Blob
      const pdfBlob = ticket(item, qrCodeDataURL);

      // Envoyer le fichier PDF au serveur local
      const formData = new FormData();
      formData.append("file", new File([pdfBlob], "label.pdf"));

      try {
        await axios.post("http://localhost:3000/print", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
      } catch (error) {
        console.error("Erreur lors de l'impression :", error);
        alert("Échec de l'impression !");
      }
    },
    async print_palette_ticket(item) {
      const doc = new jsPDF();
      this.palette_items = item.items;
      let groupedItems = [];
      // Group and accumulate quantities and count items
      if (this.palette_items) {
        groupedItems = this.palette_items.reduce((acc, product) => {
          const existing = acc.find((p) => p.label === product.label);

          if (existing) {
            existing.qte += parseFloat(product.qte.toFixed(2)); // Cumulate quantity
            existing.qte_net += parseFloat(product.qte_net.toFixed(2)); // Cumulate quantity
            existing.nbItems += 1; // Count items
          } else {
            acc.push({
              client_name: product.client_name || "Unknown Client",
              label: product.label || "Unknown Product",
              unit_colis: product.unit_colis || "Unknown Unit",
              qte: parseFloat(product.qte.toFixed(2)), // Initialize quantity
              qte_net: parseFloat(product.qte_net.toFixed(2)), // Initialize quantity
              nbItems: 1, // Initialize item count
              unit: product.unit || "", // Keep unit consistent
            });
          }
          return acc;
        }, []);
      }
      // Ticket Header
      const paletteCode = item.ref || "Unknown Ref";
      const date = item.created_at || new Date().toLocaleDateString();

      // Add QR Code
      let label = "";
      let client_name = "";
      let unit_colis = "";
      let unit = "";
      let nb_unit_colis = 0;
      groupedItems.forEach((product) => {
        label = label + product.label + "\n ";
        client_name = product.client_name;
        if (unit_colis == "" || unit_colis != product.unit_colis) {
          unit_colis = product.unit_colis;
          nb_unit_colis++;
        }
        if (unit == "" || unit != product.unit) {
          unit = product.unit;
        }
      });
      const qrCodeDataURL = await QRCode.toDataURL(
        paletteCode + "\n " + "Date Fabrication " + date + "\n" + label
      );
      doc.setFontSize(16);

      doc.setFont("helvetica", "bold");
      doc.text(`${myName}`, 70, 10);
      doc.addImage(qrCodeDataURL, "PNG", 10, 10, 30, 30); // Adjust size and position
      doc.setFont("helvetica", "normal");
      // Add Palette Information
      doc.setFontSize(12);
      doc.text(`N° Palette: ${paletteCode}`, 50, 15);
      doc.text(`Date: ${date}`, 50, 20);
      doc.text(`Client: ${client_name}`, 50, 25);
      // Table Data Preparation
      const tableData = groupedItems.map((product) => [
        product.label, // Product Label
        product.nbItems +
          (nb_unit_colis > 1
            ? " " + product.unit_colis + (product.nbItems > 1 ? "s" : "")
            : ""), // Number of Items
        `${this.numberWithSpace(product.qte.toFixed(2))} ${product.unit}`, // Total Quantity (cumulated)
        `${this.numberWithSpace(product.qte_net.toFixed(2))} ${product.unit}`, // Total Quantity (cumulated)
      ]);

      let h = ["Produit", "Nombre d'items", "Brut", "Net"];
      let w = !use_balance;
      let columnStyles = {
        0: { cellWidth: 80 }, // Label column width
        1: { cellWidth: 20, halign: "center" }, // Number of Items alignment
        2: { cellWidth: 40, halign: "center" }, // Total Quantity alignment
        3: { cellWidth: 40, halign: "center" }, // Total Quantity alignment
      };
      if (w) {
        h = ["Produit", "Nombre d'items", "Quantité"];
        columnStyles = {
          0: { cellWidth: 100 }, // Label column width
          1: { cellWidth: 20, halign: "center" }, // Number of Items alignment
          2: { cellWidth: 60, halign: "center" }, // Total Quantity alignment
        };
      }
      if (nb_unit_colis == 1) h[1] = "Nombre de " + unit_colis;
      // Add Table with AutoTable
      doc.autoTable({
        startY: 50, // Start position below the header
        head: [h],
        body: tableData,
        styles: {
          fontSize: 10, // Font size for table content
          cellPadding: 2, // Cell padding
        },
        columnStyles: columnStyles,
        headStyles: {
          fillColor: [128, 128, 128],
          textColor: [0, 0, 0], // Header text color
          fontSize: 11, // Font size for header
          halign: "center", // Center align header text
          valign: "middle",
        },
        bodyStyles: {
          textColor: [0, 0, 0], // Body text color
        },
      });

      // Footer: Total Summary
      let totalbrut = item.qte_brut ? item.qte_brut : 0;

      const totalnet = item.qte_net ? item.qte_net : 0;
      const totalItems = groupedItems.reduce(
        (sum, product) => sum + product.nbItems,
        0
      );

      const finalY = doc.lastAutoTable.finalY + 10; // Position after the table
      doc.setFontSize(12);
      let c = "Total Items";
      if (nb_unit_colis == 1) c = "Total " + unit_colis;
      doc.text(`${c}: ${totalItems}`, 10, finalY);
      if (!w) {
        c = "Poids Brut";
        totalbrut = item.qte_brut;
      } else {
        c = "Quantité";
        totalbrut = item.qte;
      }
      c = !w ? "Poids Brut" : "Quantité";
      doc.text(
        `${c} : ${this.numberWithSpace(totalbrut.toFixed(2))} ${unit || ""}`,
        80,
        finalY
      );
      if (!w)
        doc.text(
          `Poids Net : ${this.numberWithSpace(totalnet.toFixed(2))} ${
            unit || ""
          }`,
          150,
          finalY
        );

      // Open PDF for printing or saving
      window.open(doc.output("bloburl")); // Open in a new tab for printing
    },
    get_motif(m) {
      this.motif = m;
    },
    async consomed(item) {
      this.is_motif =
        this.tabs == 0 &&
        item.prc_rest > this.$store.state.options[0].seuil_justif_stock;
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          (this.tabs == 0
            ? "Considerer cet element comme etant consommé"
            : "Restorer cet element") + "-Etês-vous sûr ?",
          { color: "orange darken-3" }
        )
      ) {
        if (this.prd_type == "p") {
          let v = {
            fichedetail: {
              id: item.id,
              consomed: this.tabs == 0 ? 1 : 0,
              motif: this.tabs == 0 ? this.motif : null,
            },
          };
          let r = await this.maj(UPDATE_FICHE_DETAIL, v);
          if (r) {
            this.form_list.splice(item.index, 1);
            this.frml++;
            this.get_data();
          }
        }
        if (this.prd_type == "i") {
          let v = {
            productlist: {
              id: item.id,
              consomed: this.tabs == 0 ? 1 : 0,
              motif: this.tabs == 0 ? this.motif : null,
            },
          };
          let r = await this.maj(UPDATE_PRODUCT_LIST, v);
          if (r) {
            this.form_list.splice(item.index, 1);
            this.frml++;
            this.get_data();
          }
        }
      }
      this.is_motif = false;
    },
    async maj(query, v, refresh) {
      let r;
      this.loading = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          if (refresh) this.$store.dispatch("Changed", true);
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
          this.loading = false;
        });
      return r;
    },
    init_list() {
      this.colisheaders = [];
      this.colisheaders2.forEach((element) => {
        this.colisheaders.push(element);
      });
      this.colisheaders[2].text =
        "Livrée " + (this.product.unit ? "(" + this.product.unit + ")" : "");
      this.colisheaders[2].dec = this.product.decimal
        ? this.product.decimal
        : 2;
      this.colisheaders[3].text =
        "Reste " + (this.product.unit ? "(" + this.product.unit + ")" : "");
      this.colisheaders[3].dec = this.colisheaders[2].dec;
      let colis_attributs = [];

      colis_attributs = this.product.colisattributs
        ? this.product.colisattributs.split(",")
        : [];

      for (let index = 0; index < colis_attributs.length; index++) {
        const element = colis_attributs[index];
        let i = this.attributs_list.findIndex((elm) => elm.id == element);
        if (i >= 0) {
          let ls = [];
          this.attributs_list[i].attributvalues.forEach((element) => {
            ls.push(element.name);
          });
          let elm = {
            text: this.attributs_list[i].caption
              ? this.attributs_list[i].caption
              : this.attributs_list[i].name,
            value: "ref" + index,
            selected: true,
            edit: true,
            readonly: false,
            rules: [
              (v) =>
                !!v ||
                (this.attributs_list[i].caption
                  ? this.attributs_list[i].caption
                  : this.attributs_list[i].name) + " obligatoire",
            ],
            key: this.attributs_list[i].key,
            type:
              this.attributs_list[i].attributvalues.length > 0
                ? "autocomplete"
                : "text",
            select_list: ls,
            show_list: ls,
            sm: 6,
            md: 6,
          };
          this.colisheaders.splice(index + 2, 0, elm);
        }
      }
    },
    async get_data() {
      this.palheaders[12].hiden = false;
      this.palheaders[13].hiden = false;
      let v = {};
      let r;
      this.headers_production[11].tooltip =
        this.tabs == 0 ? "Supprimer (Etant consommé)" : "Restorer";
      this.headers_production[11].icon =
        this.tabs == 0 ? "mdi-delete" : "mdi-restore";
      this.colisheaders2[7].tooltip =
        this.tabs == 0 ? "Supprimer (Etant consommé)" : "Restorer";
      this.colisheaders2[7].icon =
        this.tabs == 0 ? "mdi-delete" : "mdi-restore";
      this.headers_production[9].hiden = this.tabs == 1;
      this.colisheaders2[6].hiden = this.tabs == 1;
      this.width = this.tabs == 1 ? "1200px" : "1600px";
      if (this.prd_type == "i") {
        v = {
          consomed: this.tabs,
        };
        r = await this.requette(PRODUCT_LIST_CUMULE, v);
        if (r) {
          this.encours_list = r.product_list_cumule;
          this.klist++;
        }
      }
      if (this.prd_type == "p") {
        //produit fini
        if (this.$store.state.auth.includes("02024")) {
          v = {
            receipt: 1,
            tosell: 1,
          };
        }
        //matiere premiere
        if (this.$store.state.auth.includes("02044")) {
          v = {
            receipt: 1,
            tobuy: 1,
            tosell: 0,
          };
        }
        r = await this.requette(PRODUCTION_LIST_CUMULE, v);
        if (r) {
          this.encours_list = r.production_list_cumule;
          this.klist++;
        }
      }
      v = {
        TypeScope: 1,
      };
      r = await this.requette(ALLATTRIBUTS, v);
      if (r) {
        this.attributes = r.allattributes;
      }
    },
    async requette(query, v) {
      let r;
      this.loading = true;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },

    ProductSelect(item) {
      this.product = item;
      this.selitem = item;
      this.headers_production[11].hiden = !(
        (this.$store.state.auth.includes("02095") ||
          this.$store.state.isadmin) &&
        (this.product.of_statut = 7 || this.product.of_statut == null) &&
        this.product.statut_id == "0"
      );
      this.colisheaders2[7].hiden = !(
        (this.$store.state.auth.includes("02096") ||
          this.$store.state.isadmin) &&
        (this.product.of_statut = 7 || this.product.of_statut == null)
      );
    },
    numberWithSpace: function (x) {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
    },
    PaletteChange(item) {
      this.palette = item;
      this.last_palette = this.palette;
      this.palette_items = this.palette.items;
      this.kpal++;
    },
    async ProductOpen(item) {
      this.product = item;
      if (item.col == "nbpfd") {
        if (this.prd_type == "p") {
          this.headers_production[2].text =
            "Qte" + (this.product.unit ? "(" + this.product.unit + ")" : "");
          this.headers_production[3].text =
            "Reste " + (this.product.unit ? "(" + this.product.unit + ")" : "");
          this.headers_production[8].hiden = this.prd_type == "p";
          this.headers_production[10].hiden =
            item.conforme == 0 || this.prd_type == "i";
          this.headers_detail = this.headers_production;
          this.title =
            "Produit : " +
            item.article_code +
            "-" +
            item.article_label +
            (item.gamme_name ? "-" + " Etat " + item.gamme_name : "");
          let v = {
            article_id: item.article_id,
            of_id: item.of_id,
            gamme_id: item.gamme_id,
            consomed: this.prd_type == "p" ? 0 : this.tabs,
            conforme: item.conforme,
          };

          let r = await this.requette(PRODUCTION_LIST, v);
          if (r) {
            this.form_list = r.production_list;
            this.frml++;
          }
        }

        if (this.prd_type == "i") {
          this.init_list();
          this.headers_detail = this.colisheaders;
          this.title =
            "Produit : " +
            item.article_code +
            "-" +
            item.article_label +
            "-" +
            (item.no_order
              ? " OF N° " + item.no_order
              : " Machine " + item.machine_name);
          let v = {
            article_id: item.article_id,
            of_id: item.of_id,
            machine_id: item.machine_id,
            consomed: this.tabs,
            stock_id: 1,
          };

          let r = await this.requette(PRODUCT_LIST_INPUT, v);
          if (r) {
            this.form_list = r.product_list_input;
            this.frml++;
          }
        }
      }
      if (item.col == "nbpal") {
        this.palheaders[12].hiden = true;
        this.palheaders[13].hiden = true;
        this.headers_detail = this.palheaders;
        this.title =
          "Produit : " +
          item.article_code +
          "-" +
          item.article_label +
          (item.gamme_name ? "-" + " Etat " + item.gamme_name : "");
        let v = {
          article_id: item.article_id,
          of_id: item.of_id,
          gamme_id: item.gamme_id,
          consomed: this.prd_type == "p" ? 0 : this.tabs,
        };

        let r = await this.requette(PRODUCTION_LIST, v);
        if (r) {
          const distinctPalIds = [
            ...new Set(r.production_list.map((elm) => elm.pal_id)),
          ];
          v = {
            where: {
              column: "ID",
              operator: "IN",
              value: distinctPalIds,
            },
          };

          r = await this.requette(ALLPALETTES, v);
          if (r) {
            this.form_list = r.allpalettes;
            this.frml++;
          }
        }
      }
      this.isListClose = false;
      this.frml++;
    },
  },
};
</script>
